import { createStore } from 'vuex';

export default createStore({
  state: {
    name: '',
  },
  mutations: {
    setName(state, name) {
      state.name = name;
    },
    initStore(state) {
      const storageData = localStorage.getItem('store');
      if (storageData) {
        this.replaceState(
          Object.assign(
            state,
            JSON.parse(storageData),
          ),
        );
      }
    },
  },
  actions: {
  },
  modules: {
  },
});
