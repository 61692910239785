
import { defineComponent } from 'vue';
import Locale from './components/Locale.vue';

export default defineComponent({
  name: 'main-component',
  components: {
    Locale,
  },
});
