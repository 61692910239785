import { createApp, State } from 'vue';
import { MutationPayload } from 'vuex';
import { createI18n } from 'vue-i18n';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import './assets/sass/main.scss';
import { messages } from './locale';

store.commit('initStore');

store.subscribe((_mutation: MutationPayload, state: State) => {
  localStorage.setItem('store', JSON.stringify(state));
});

const locale = localStorage.getItem('locale') || 'fi';

const i18n = createI18n({
  locale,
  fallbackLocale: 'fi',
  messages,
});

createApp(App).use(store).use(router).use(i18n)
  .mount('#app');
