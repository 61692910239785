export const messages = {
  en: {
    chooseNickname: 'Choose a nickname',
    chooseGameId: 'Insert game id to join to',
    nickname: 'Nickname',
    createGame: 'Create game',
    joinGameText: 'Game Id',
    joinGame: 'Join game',
    roomId: 'Room ID',
    chooseStart: 'Choose who starts',
    players: 'Players',
    turn: 'Turn',
    leave: 'Leave',
    chooseWinner: 'Choose winner',
    choosePlayer: 'Choose player',
    throwDie: 'Throw die',
    throwDice: 'Throw dice',
    choose: 'Choose',
    move: 'Move',
    endTurn: 'End turn',
    none: '',
    myTurn: 'It\'s my turn',
  },
  fi: {
    chooseNickname: 'Valitse nimimerkki',
    chooseGameId: 'Pelin ID mihin liittyä',
    nickname: 'Nimimerkki',
    createGame: 'Luo peli',
    joinGameText: 'Pelin ID',
    joinGame: 'Liity peliin',
    roomId: 'Huone ID',
    chooseStart: 'Valitkaa aloittaja',
    players: 'Pelaajat',
    turn: 'Vuoro',
    leave: 'Poistu',
    chooseWinner: 'Valitse voittaja',
    choosePlayer: 'Valitse pelaaja',
    throwDie: 'Heitä noppa',
    throwDice: 'Heitä nopat',
    choose: 'Valitse',
    move: 'Siirry',
    endTurn: 'Lopeta vuoro',
    none: '',
    myTurn: 'On minun vuoroni',
  },
};
export default {
  messages,
};
