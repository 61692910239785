
import { defineComponent } from 'vue';

type ComponentData = {
  languages: string[];
  index: number;
}

export default defineComponent({
  name: 'locale',
  data(): ComponentData {
    return {
      languages: ['fi', 'en'],
      index: 0,
    };
  },
  computed: {
    filteredLanguages(): string[] {
      return this.languages.filter((language) => language !== this.$i18n.locale);
    },
    getLang(): string {
      return this.$i18n.locale;
    },
  },
  methods: {
    changeLanguage(language: string): void {
      localStorage.setItem('locale', language);
      this.$i18n.locale = language;
    },
  },
});
