<template>
  <locale v-if="$router.currentRoute.value.name !== 'Observe'"></locale>
  <router-view/>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import Locale from './components/Locale.vue';

export default defineComponent({
  name: 'main-component',
  components: {
    Locale,
  },
});
</script>
